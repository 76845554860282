import {
  handleFilteredPicklistChange,
  isNonEmptyString,
} from "../components/form/Helpers";
import { getLookupData } from "../js/utility";
import lookups from "./lookups";

export const ProductBundleItemFormStages = [
  {
    title: "General",
    id: "general",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_productbundleid",
            className: "col-md-4",
            description: "",
            label: "Product Bundle",
            lookup: lookups.g4b_productbundle.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4b_productbaseid",
            className: "col-md-4",
            description: "",
            handleChange: (
              event,
              handleChange,
              fieldName,
              values
            ) => {
              handleChange({
                event,
                target: {
                  name: "g4b_defaultvarianttypeid",
                  value: "",
                },
              });
              handleFilteredPicklistChange(
                event,
                handleChange,
                fieldName,
                values
              );
            },
            label: "Bookable Product",
            lookup: lookups.g4b_productbase.all,
            mapOptions: (options, state) => {
              //Filter out any products where there are no variants set
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              );

              return options.filter((o, i) => {
                return productVariants.some(
                  (x) => x.ProductId === o.Key
                );
              });
            },
            required: true,
            type: "lookup",
          },
          //Only adding this here so the g4b_protoproduct records lookup can be loaded
          //for use in the mapOptions for g4b_defaultvarianttypeid
          {
            name: "protoproductslookup",
            lookup: lookups.g4b_protoproduct.all,
            hidden: true,
          },
          {
            name: "g4b_defaultvarianttypeid",
            className: "col-md-4",
            description: "",
            disabledIfFromValues: (values) =>
              !isNonEmptyString(values.g4b_productbaseid),
            label: "Variant Type",
            lookup: lookups.g4b_varianttype.all,
            mapOptions: (options, state, values) => {
              // check a product has been selected
              if (!isNonEmptyString(values.g4b_productbaseid)) {
                return [];
              }

              // find product variants for the selected product
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              ).filter(
                (x) => x.ProductId === values.g4b_productbaseid
              );

              if (productVariants.length === 0) {
                return [];
              }

              // find the variant types for the product variants
              const variantTypeIds = productVariants.map(
                (x) => x.VariantTypeId
              );

              // filter the options based on the available product variants
              return options.filter((x) =>
                variantTypeIds.includes(x.Key)
              );
            },
            required: true,
            showErrorsBeforeTouched: true,
            type: "lookup",
            validate: (value, values, state) => {
              if (!isNonEmptyString(values.g4b_productbaseid)) {
                // don't show error message if the product is not selected
                return "";
              }

              // find product variants for the selected product
              const productVariants = getLookupData(
                lookups.g4b_protoproduct.all.name,
                state
              ).filter(
                (x) => x.ProductId === values.g4b_productbaseid
              );
              if (productVariants.length === 0) {
                return "There are no variants for the selected product. Please choose another product or create product variants for this product.";
              }

              return !value ? "Required" : "";
            },
          },
          //Only adding these so that when we load up an existing item record, these will be
          //populated so that if we then save without changing the g4b_productbaseid
          //or g4b_defaultvarianttypeid values,
          //these will stay the same and thus keep the product name in the bundle item
          //related entity table on the product bundle form
          {
            name: "g4b_productbaseidname",
            hidden: true,
          },
          {
            name: "g4b_defaultvarianttypeidname",
            hidden: true,
          },
          {
            name: "g4b_collectgiftaid",
            className: "col-md-4",
            description: "",
            label: "Collect Gift Aid?",
            type: "bit",
          },
          {
            name: "g4b_fixedprice",
            className: "col-md-4",
            description: "",
            label: "Fixed Price",
            type: "money",
          },
          {
            name: "g4b_percentage",
            className: "col-md-4",
            description: "",
            label: "Percentage",
            type: "decimal",
            validate: (value) => {
              const isValid = value >= 0 && value <= 100;
              return isValid
                ? ""
                : "You must enter a number between 0 and 100";
            },
          },
          {
            name: "g4b_fixedrevenueshare ",
            className: "col-md-4",
            description: "",
            label: "Fixed Revenue Share",
            type: "bit",
          },
        ],
      },
    ],
  },
];
