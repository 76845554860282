import { useRef, useState } from "react";
import { withFormik } from "formik";
import {
  Button,
  Loading,
  Modal,
  SubmitFormPage,
} from "../elements/_Elements";
import SubForm from "./SubForm";
import { DispatchMethods } from "./enums";
import {
  ButtonStyle,
  LoadingState,
  OverviewColumn,
} from "../../js/enums";
import {
  allowEditOnOverview,
  checkForAnyEditableStages,
  checkIfFieldRequired,
  checkIfRequiredFieldValueValid,
  createOrUpdateRecord,
  displayStage,
  generateRedirectURL,
  getCustomAction,
  getFieldInitialValue,
  getLookupsForStages,
  getOverviewIndex,
  handleRedirect,
  mapPropsToValuesForStages,
  useLookupOptions,
} from "./Helpers";
import "./overview.scss";
import OverviewStage from "./OverviewStage";

function OverviewForm({
  auditLabel,
  cancelSubForm,
  closeRedirect,
  customCopyComponent,
  dispatch,
  entityName,
  errors,
  isSubForm,
  isSubmitting,
  handleBlur,
  handleChange,
  handleSubmit,
  hideFormWhileSubmitting = false,
  globalDispatch,
  onSubmitSubForm,
  parentFormEntityName,
  parentFormState,
  setFieldValue,
  showCopyButton,
  showInfo,
  showSaveAndCloseButton = true,
  showSaveButton = true,
  showSaveAndNewButton = false,
  stages,
  state,
  touched,
  values,
}) {
  const pageRefs = useRef([]);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [subForm, setSubForm] = useState("");
  const [customAction, setCustomAction] = useState("");
  const [displayCancelConfirmModal, setDisplayCancelConfirmModal] =
    useState(null);

  const lookupOptions = getLookupsForStages(
    stages.filter((stage) => allowEditOnOverview(stage, state)),
    state
  );

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    lookupOptions,
    setLoading,
    state
  );

  const onBack = () => {
    dispatch({
      type: DispatchMethods.SetRedirect,
      redirect: generateRedirectURL(closeRedirect),
    });
  };

  const scrollToElement = (id) => {
    if (!id) {
      return;
    }
    const ref = pageRefs.current.find((r) => r.id === id);
    if (ref) {
      ref.style.scrollMargin = `${
        document.getElementById("form-page-header").offsetHeight
      }px`;
      ref.scrollIntoView({ behavior: "smooth" });
    }
  };

  const mainColumnStages = stages.filter((s) => {
    return (
      !s.overviewColumn || s.overviewColumn === OverviewColumn.Main
    );
  }); //Assume stages with no overviewColumn value set are to be placed on the main column by default
  const sideColumnStages = stages.filter((s) => {
    return (
      s.overviewColumn && s.overviewColumn === OverviewColumn.Side
    );
  });

  return loading !== LoadingState.Loaded ||
    (hideFormWhileSubmitting && isSubmitting) ? (
    <Loading />
  ) : (
    <>
      {displayCancelConfirmModal && (
        <Modal
          title={"Changes will be lost"}
          modalCloseButtonClick={() => {
            setDisplayCancelConfirmModal(false);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <p>
              If you click confirm, you will lose any unsaved changes
            </p>
          </div>
          <div className="modal-footer">
            <Button
              text={"Confirm"}
              style={ButtonStyle.Primary}
              onClick={() => onBack()}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => {
                setDisplayCancelConfirmModal(false);
              }}
            />
          </div>
        </Modal>
      )}
      {subForm && (
        <SubForm
          {...{
            dispatch,
            entityName,
            errors,
            setSubForm,
            state,
            subForm,
            values,
          }}
        />
      )}
      {customAction &&
        getCustomAction(
          customAction,
          setFieldValue,
          setCustomAction,
          state,
          values
        )}
      <SubmitFormPage
        {...{ auditLabel, showInfo }}
        backText={state.id ? "Back" : "Cancel"}
        customCopyComponent={customCopyComponent}
        dispatch={dispatch}
        disabled={subForm !== "" || customAction !== ""}
        entityName={entityName}
        errors={errors}
        header={state.name}
        isBusy={isSubmitting}
        loading={loading}
        onBack={
          isSubForm && cancelSubForm
            ? () => cancelSubForm()
            : closeRedirect
            ? checkForAnyEditableStages(stages, state)
              ? () => setDisplayCancelConfirmModal(true)
              : () => onBack()
            : null
        }
        onSubmit={handleSubmit}
        onSubmitSubForm={onSubmitSubForm}
        scrollToElement={scrollToElement}
        setLoading={() => setLoading()}
        showButtonSection={true}
        submitText={"Save"}
        submitButtonClickEvent={() => setFieldValue("action", "save")}
        saveAndCloseSubmitButtonClickEvent={() =>
          setFieldValue("action", "saveAndClose")
        }
        setFieldValue={setFieldValue}
        showSaveAndCloseButton={
          showSaveAndCloseButton &&
          (!state.id || checkForAnyEditableStages(stages, state))
        }
        showSaveButton={
          showSaveButton &&
          (!state.id || checkForAnyEditableStages(stages, state))
        }
        stage={stages[getOverviewIndex(stages) - 2]}
        stages={stages}
        state={state}
        values={values}
        showCopyButton={
          showCopyButton && checkForAnyEditableStages(stages, state)
        }
        showSaveAndNewButton={
          showSaveAndNewButton &&
          (!state.id || checkForAnyEditableStages(stages, state))
        }
        saveAndNewSubmitButtonClickEvent={() =>
          setFieldValue("action", "saveAndNew")
        }
      >
        <div className="row">
          <div
            className={
              sideColumnStages.length > 0 ? "col-md-8" : "col-12"
            }
          >
            {mainColumnStages &&
              mainColumnStages
                .filter((stage) => displayStage(stage, state))
                .map((stage, i) => {
                  return (
                    <OverviewStage
                      key={i}
                      index={i}
                      {...{
                        customAction,
                        dispatch,
                        entityName,
                        errors,
                        handleBlur,
                        handleChange,
                        pageRefs,
                        parentFormEntityName,
                        parentFormState,
                        setCustomAction,
                        setFieldValue,
                        setSubForm,
                        stage,
                        stages,
                        state,
                        subForm,
                        touched,
                        values,
                      }}
                    />
                  );
                })}
          </div>
          {sideColumnStages.length > 0 && (
            <div className="col-md-4">
              {sideColumnStages
                .filter((stage) => displayStage(stage, state))
                .map((stage, i) => {
                  return (
                    <OverviewStage
                      key={i}
                      index={i}
                      {...{
                        customAction,
                        dispatch,
                        entityName,
                        errors,
                        handleBlur,
                        handleChange,
                        pageRefs,
                        parentFormEntityName,
                        parentFormState,
                        setCustomAction,
                        setFieldValue,
                        setSubForm,
                        stage,
                        stages,
                        state,
                        subForm,
                        touched,
                        values,
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </SubmitFormPage>
    </>
  );
}

const OverviewWithFormik = withFormik({
  mapPropsToValues: (props) =>
    mapPropsToValuesForStages(
      props.stages.filter((stage) =>
        allowEditOnOverview(stage, props.state)
      ),
      props.state,
      props.parentFormEntityName,
      props.parentFormFieldLookup,
      props.parentFormState,
      props.parentFormValues,
      props.linkedEntities
    ),

  validate: (values, props) => {
    const errors = {};

    props.stages
      .filter((stage) => allowEditOnOverview(stage, props.state))
      .forEach((stage) => {
        stage.sections
          .filter((section) => section.fields)
          .forEach((section) => {
            section.fields.forEach((field) => {
              if (
                checkIfFieldRequired(
                  field,
                  props.state,
                  values,
                  props.globalState
                ) &&
                !checkIfRequiredFieldValueValid(field, values)
              ) {
                errors[field.name] = "Required";
              } else if (field.validate) {
                const error = field.validate(
                  values[field.name],
                  values,
                  props.state
                );
                if (
                  error &&
                  (!Array.isArray(error) || error.length > 0)
                ) {
                  errors[field.name] = error;
                }
              }
            });
          });
      });

    return errors;
  },

  handleSubmit: async (values, { props, setFieldValue }) => {
    const {
      dispatch,
      entityName,
      globalDispatch,
      methodName,
      refreshPageUponSave,
      saveAndCloseRedirect,
      stages,
      state,
      saveAndNewRedirect,
    } = props;

    const closeRedirect = props.closeRedirect
      ? props.closeRedirect
      : new URLSearchParams(window.location.search).get(
          "closeRedirect"
        );

    stages.forEach((stage) => {
      if (stage.updateValuesBeforeSubmit) {
        stage.updateValuesBeforeSubmit(state, values);
      }
    });
    const updatedState = { ...state, ...values };

    const result = await createOrUpdateRecord(
      values.action,
      entityName,
      globalDispatch,
      stages,
      updatedState,
      methodName
    );

    if (result) {
      if (typeof result === "string") {
        // result is the id
        if (values && values.action) {
          handleRedirect(
            values.action === "saveAndNew"
              ? saveAndNewRedirect
              : saveAndCloseRedirect
              ? saveAndCloseRedirect
              : closeRedirect,
            dispatch,
            result,
            values.action === "save" && refreshPageUponSave
              ? "refreshPage"
              : values.action,
            updatedState
          );
        }
      } else {
        // determine the updated state
        const { Fields, Id, Name, RelatedEntities } = result;
        const newState = {
          ...state,
          id: Id,
          name: Name,
          ...Fields,
          relatedEntities:
            RelatedEntities != null && RelatedEntities.length > 0
              ? RelatedEntities.map((relatedEntity) => {
                  return {
                    entityName: relatedEntity.EntityName,
                    entities: relatedEntity.Entities.map((entity) => {
                      return {
                        Id: entity.Id,
                        Name: entity.Name,
                        Fields: entity.Fields,
                      };
                    }),
                  };
                })
              : [],
        };

        //bind the update values to the current form values
        const overviewStages = props.stages.filter((stage) =>
          allowEditOnOverview(stage, props.state)
        );
        overviewStages.forEach((stage) => {
          stage.sections
            .filter((section) => section.fields)
            .forEach((section) => {
              section.fields.forEach((field) => {
                // pass null for urlParams, parentFormValues and linkedEntities as we want to use the value already bound
                setFieldValue(
                  field.name,
                  getFieldInitialValue(
                    newState,
                    field,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  ),
                  false
                );
              });
            });
        });

        props.dispatch({
          type: DispatchMethods.UpdateRecord,
          values: { ...newState },
        });

        if (values && values.action) {
          handleRedirect(
            closeRedirect,
            dispatch,
            result.Id,
            values.action === "save" && refreshPageUponSave
              ? "refreshPage"
              : values.action
          );
        }
      }
    }
  },
})(OverviewForm);

export default OverviewWithFormik;
