import DefaultLayout from "./layouts/DefaultLayout";
import { FormProvider } from "./components/form/FormContext";
import Form from "./components/form/Form";
import { useGlobalState } from "./GlobalContext";
import { userHasCorrectRole } from "./js/roles";
import { Alert } from "./components/elements/_Elements";
import { AlertStyle } from "./js/enums";

export default function FormPage({
  allowedRoles,
  auditLabel,
  closeRedirect,
  customCopyComponent,
  entityName,
  hideFormWhileSubmitting,
  loadDataMethod,
  methodName,
  refreshPageUponSave,
  renderSidebar,
  saveAndCloseRedirect,
  saveAndNewRedirect,
  showCopyButton,
  showInfo,
  showSaveAndCloseButton,
  showSaveButton,
  showSaveAndNewButton,
  stages,
}) {
  const globalState = useGlobalState();
  const { user } = globalState;

  const hasCorrectRole = userHasCorrectRole(user, allowedRoles);

  return !hasCorrectRole ? (
    <DefaultLayout>
      {user && (
        <Alert
          className="m-3"
          style={AlertStyle.Danger}
          text={"Invalid Role"}
        /> //Only show the alert if the user has been loaded
      )}
    </DefaultLayout>
  ) : (
    <DefaultLayout {...{ renderSidebar }}>
      <FormProvider>
        <Form
          {...{
            auditLabel,
            closeRedirect,
            customCopyComponent,
            entityName,
            hideFormWhileSubmitting,
            loadDataMethod,
            methodName,
            refreshPageUponSave,
            saveAndCloseRedirect,
            saveAndNewRedirect,
            showCopyButton,
            showInfo,
            showSaveAndCloseButton,
            showSaveButton,
            showSaveAndNewButton,
            stages,
          }}
        />
      </FormProvider>
    </DefaultLayout>
  );
}
