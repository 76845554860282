import React, { useState } from "react";
import { AlertStyle, ButtonStyle } from "../js/enums";
import { useGlobalReducer } from "../GlobalContext";
import { useFormReducer } from "../components/form/FormContext";
import { Update } from "../js/service";
import { DispatchMethods } from "../components/form/enums";
import { Alert, Button } from "../components/elements/_Elements";
import { convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue } from "../js/utility";

function SetOffHoldDateToToday({ state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);

  const reprocess = async () => {
    setIsSubmitting(true);
    try {
      const successMessage = "Contact Taken Off Hold";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: state.g4b_name,
            Attributes: {
              g4b_datetimeoffhold:
                convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
                  new Date()
                ),
              g4b_onhold_contactid: state.g4b_onhold_contactid,
              g4b_onholdreasonid: state.g4b_onholdreasonid,
            },
          },
          "g4b_onholdaudit"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        dispatch({
          type: DispatchMethods.UpdateRecord,
          values: { g4b_datetimeoffhold: new Date() },
        });
        setIsSubmitting(false);
        setDisplayAlert(true);
      }
    } catch (error) {
      console.error(error);
      setDisplayAlert(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        className="text-decoration-none"
        style={ButtonStyle.Primary}
        text={"Set Off Hold Date to Today"}
        onClick={() => reprocess()}
        showSpinner={isSubmitting}
        disabled={isSubmitting}
      />
      {displayAlert && (
        <Alert
          className="text-center m-0 mt-3"
          style={AlertStyle.Info}
          text={"Contact Taken Off Hold"}
        />
      )}
    </>
  );
}

export default SetOffHoldDateToToday;
